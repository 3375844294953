import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import $ from 'jquery';

// Функция для открытия или создания базы данных
function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("Comsign-Preview", 1);

    // Создаем хранилище при первой инициализации
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore("base64", { keyPath: "seed" }); // "seed" как уникальный ключ
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
}

// Получение всех данных из IndexedDB при загрузке
async function getCachedQueue() {
  const db = await openDatabase();
  const transaction = db.transaction("base64", "readonly");
  const store = transaction.objectStore("base64");

  return new Promise((resolve, reject) => {
    const request = store.getAll(); // Извлекаем все записи
    request.onsuccess = () => {
      // console.log("Данные из IndexedDB загружены:", request.result);
      resolve(request.result); // Возвращаем все записи как массив объектов
      db.close();
    };
    request.onerror = () => {
      // console.error("Ошибка загрузки данных из IndexedDB:", request.error);
      reject(request.error);
      db.close();
    };
  });
}

// Добавление или обновление данных в IndexedDB
async function setCacheQueue(data) {
  const db = await openDatabase();
  const transaction = db.transaction("base64", "readwrite");
  const store = transaction.objectStore("base64");

  // console.log("SET CACHE");
  // console.log(data);

  return new Promise((resolve, reject) => {
    const request = store.put(data); // Добавление или обновление записи
    request.onsuccess = () => {
      // console.log(`Данные с ключом ${data.seed} добавлены или обновлены в IndexedDB.`);
      resolve();
      db.close();
    };
    request.onerror = () => {
      // console.error("Ошибка при записи в IndexedDB:", request.error);
      reject(request.error);
      db.close();
    };
  });
}

async function initCache() {
  try {
    const data = await getCachedQueue();
    if (data.length > 0) {
      // console.log("Данные из IndexedDB успешно загружены.");
    } else {
      // console.log("Данные в IndexedDB отсутствуют.");
    }
    return data; // Возвращаем массив данных
  } catch (error) {
    // console.error("Ошибка при инициализации кэша:", error);
    return [];
  }
}

async function handleServerData(serverData) {
  for (const item of serverData) {
    const { seed, string } = item; // Извлекаем seed и строку из каждого объекта
    await setCacheQueue(seed, string);
  }
  // console.log("Данные с сервера успешно добавлены в IndexedDB.");
}

async function handleCacheSeed(seeds) {
  const db = await openDatabase();
  const transaction = db.transaction('base64', 'readonly');
  const store = transaction.objectStore('base64');

  // console.log("asdasdsads");
  // console.log(seeds);
  return new Promise((resolve, reject) => {
    const items = [];
    // console.log("asdasdsads");

    // Обрабатываем каждый seed из списка
    // console.log(seeds);
    seeds.forEach(seed => {
      const request = store.get(seed);
      
      request.onsuccess = function(event) {
        if (request.result !== undefined) {
          items.push(request.result); // Добавляем найденный элемент в массив
        } else {
          // console.log(`Элемент с ключом ${seed} не найден.`);
        }

        // Проверяем, обработаны ли все seed
        if (items.length === seeds.length) {
          // console.log('Все выбранные элементы:', items);
        }
      };

      request.onerror = function(event) {
        console.error(`Ошибка при получении элемента с ключом ${seed}:`, event.target.error);
      };
    });

    transaction.oncomplete = function() {
      // console.log('Транзакция завершена.');
      resolve(items);
    };

    transaction.onerror = function() {
      // console.error('Ошибка при выполнении транзакции:', transaction.error);
      reject();
    };
  });
};

async function deleteCacheSeed(seed) {
  const db = await openDatabase();
  const transaction = db.transaction("base64", "readwrite"); // используем режим "readwrite" для удаления
  const store = transaction.objectStore("base64");

  return new Promise((resolve, reject) => {
    const request = store.delete(seed); // Удаление элемента по ключу seed
    request.onsuccess = () => {
      // console.log(`Элемент с ключом ${seed} успешно удален из IndexedDB.`);
      resolve();
      db.close();
    };
    request.onerror = () => {
      // console.error("Ошибка при удалении элемента из IndexedDB:", request.error);
      reject(request.error);
      db.close();
    };
  });
}

export default {
  getCachedQueue,
  setCacheQueue,
  initCache,
  handleServerData,
  handleCacheSeed,
  deleteCacheSeed
};